import React from 'react'
import Layout from '../../components/layout/layout'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'
import SideBarSeThree from "../../components/layout/sidemenu/sidemenu_se_three"

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'

import ContentHeader from '../../components/page-sections/content-header'
import FocusQuestion from '../../components/page-sections/focus-question'
import Introduction from '../../components/page-sections/introduction'
import ButtonTop from '../../components/buttons/button-top'
import ButtonNext from '../../components/buttons/button-next'

import rowImage from '../../queries/images/row-image'

import unit3Icon from '../../images/icons/unit_03_icon.svg'
import elNinoNinaCycles11 from '../../images/student-pages/unit-3/11_el_nino_nina_cycles.gif'
import stratAero13 from '../../images/student-pages/unit-3/13_stratospheric_aerosols.gif'
import sunspotsFaculaeA16 from '../../images/student-pages/unit-3/16a_sunspots_and_faculae.jpg'
import sunspotsFaculaeB16 from '../../images/student-pages/unit-3/16b_sunspots_and_faculae.jpg'
import sunspotBrightening17 from '../../images/student-pages/unit-3/17_sunspot_brightening_cycle.jpg'
import netSolar18 from '../../images/student-pages/unit-3/18_net_solar_irradiance.jpg'
import satelliteBuild19 from '../../images/student-pages/unit-3/19_sorce_satellite_build.jpg'
import satelliteSpace20 from '../../images/student-pages/unit-3/20_sorce_satellite_space.jpg'


const TestingForcings32 = (props) => (
  <Layout location={props.location.pathname}>
    <SEO title="Carbon Connections - 3.2 Testing Forcings" />
    <Container className="mb-5" fluid>
      <Row>
        <Col md="auto">
          <SideBarSeThree location={props.location.pathname} />
        </Col>
        <Col>
          <CCBreadcrumb
            pathname={props.location.pathname}
            title={'3.2 Testing Forcings'}
            replace={props.replace}
          />
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
          <Card>
            <Card.Body>

              <ContentHeader
                icon={ unit3Icon }
                iconAlt="Unit 3 icon"
                studentPageHeader="Unit 3: Carbon in the Future &amp; You"
                unitNumber="3.2"
                studentPageHeaderSub="Testing Forcings"
                sectionA={ <>Four Forcings</> }
                sectionALink="unit-3/3.2-testing-forcings/#four-forcings"
                sectionB={ <>El Ni&ntilde;o/La Ni&ntilde;a Cycles</> }
                sectionBLink="unit-3/3.2-testing-forcings/#el-nino-la-nina-cycles"
                sectionC={ <>Volcanic Aerosols</> }
                sectionCLink="unit-3/3.2-testing-forcings/#volcanic-aerosols"
                sectionD={ <>Solar Cycles</> }
                sectionDLink="unit-3/3.2-testing-forcings/#solar-cycles"
                sectionE={ <>Human Factors</> }
                sectionELink="unit-3/3.2-testing-forcings/#human-factors"
                sectionF={ <>Reflect and Connect</> }
                sectionFLink="unit-3/3.2-testing-forcings/#reflect-and-connect"
              />

              <FocusQuestion
                focusQuestionBodyText={ <>What factors influence Earth's temperature?</> }
              />

              <Introduction
                paragraphOne={
                  <>
                    <Row className="d-md-none">
                      <Col>
                        <Img
                          className="rounded img-fluid mb-3"
                          fluid={props.data.image9.childImageSharp.fluid}
                          alt="Volcanic eruption"
                        />
                      </Col>
                    </Row>
                    <Img
                      className="smallImageRight img-fluid d-none d-md-block mb-3 ml-3 mb-2"
                      fluid={props.data.image9.childImageSharp.fluid}
                      alt="Volcanic eruption"
                    />

                    <p>
                      In Lesson 3.1, you looked at Earth's temperature over the past 120 years. For you, that global view may be a new way to think about air temperature. At your home, you probably feel differences in temperature between day and night, or between seasons. For the Earth, scientists average out those kinds of differences between locations by using thousands of stations all over the Earth.
                    </p>
                  </>
                }

                paragraphTwo={
                  <>
                    <p>
                      In this lesson, you will test four influences on that temperature record. These were at the end of Lesson 3.1: El Ni&ntilde;o/La Ni&ntilde;a cycles, volcanic eruptions, solar cycles, and human influences. The climate model you will use in Carbon Connections is a real climate model, similar to the ones used by scientists at NASA, the Naval Research Laboratory, and other scientific organizations. Using the model, you will get some specific answers for the focus question. In particular, you will learn in Lesson 3.2 that:
                    </p>
                    <ul>
                      <li>Some climate forcings lead to a change in Earth's average temperature.</li>
                      <li>Some climate forcings lead to pattern of cycles in Earth's average temperature, but no net change.</li>
                      <li>Scientific models help you understand relationships in the Earth system.</li>
                    </ul>
                  </>
                }
              />

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="four-forcings" className="cardHeader">
                      <h2>
                        A. <span className="headerFontBlue">Four Forcings</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        Scientists have shown that Earth's temperature responds to the four forcings described below. You will get to test each of these forcings in this activity. You might wonder about other forcings, but the four below are the best predictors of changes in Earth's temperature. The Climate Model will help you test the influence of each forcing on Earth's temperature.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="el-nino-la-nina-cycles" className="cardHeader">
                      <h2>
                        B. <span className="headerFontBlue">Forcing: El Ni&ntilde;o/La Ni&ntilde;a Cycles</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        Perhaps you have heard someone say, "We are getting extra rain because of El Ni&ntilde;o." Or maybe they've said, "rainfall is a lot less." You might have heard that El
                        Ni&ntilde;o events are in the tropics. So, besides rainfall, do El Ni&tilde;o events influence global temperatures?
                      </p>

                      <Row>
                        <Col md={12}>
                          <div className="image-wrapper">
                            <Img
                              className="image image-bordered"
                              fluid={props.data.image10.childImageSharp.fluid}
                              alt="El Nino, January 1988. La Nina, June 1988"
                            />
                            <div className="image-caption image-caption-bordered">
                              The cause of El Ni&ntilde;o/ La Ni&ntilde;a cycles is not well understood. Sea surface temperatures (SSTs) go between warmer than average (reds), and cooler than average (blue). (a) An El Ni&ntilde;o event in January, 1998, in the eastern Pacific Ocean. (b) A La Ni&ntilde;a event in June 1998. The warm SSTs can quickly change to cool SSTs only months later.
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <p>
                        But first, what is El Ni&ntilde;o? It is a period of high sea surface temperatures (SSTs) in the Pacific Ocean along the equator. El Ni&ntilde;o is Spanish for "the boy," referring to the Christ child, because in some years the warm waters occur around Christmas. El Ni&ntilde;o can be strong along the Pacific Coast of South America, changing surface winds back toward the continent. This reduces upwelling of nutrient-rich waters to the <strong>photic zone</strong>. The photic zone is the upper layer of the ocean where photosynthesis occurs. (For more, click on the El Ni&ntilde;o Animation below.) You saw in Unit 2 how nutrient-rich waters are part of the marine food web and the carbon cycle. Typically, the west of South America is one area of very high marine productivity.
                      </p>

                      <p>
                        A La Ni&ntilde;a (Spanish for "the girl") event is the opposite of an El Ni&ntilde;o event. La Ni&ntilde;a events are periods of colder ocean temperatures in the same region. During a La Ni&ntilde;a, upwelling cold waters move to the ocean surface off the coast of South America. This increases productivity in the marine ecosystem, and benefits fisheries and food production. Together, El Ni&ntilde;o and La Ni&ntilde;a form repeating cycles, as shown in the graph.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={ elNinoNinaCycles11 }
                            alt="El Nino/La Nina Cycles (ENSO), 1979-2011"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter img-fluid d-none d-md-block mb-3"
                        src={ elNinoNinaCycles11 }
                        alt="El Nino/La Nina Cycles (ENSO), 1979-2011"
                      />

                      <p className="lead">Climate Model &amp; El Ni&ntilde;o/La Ni&ntilde;a Cycles</p>
                      <p>
                        Do these steps to investigate the influence of El Ni&ntilde;o/La Ni&ntilde;a cycles on Earth's temperature.
                      </p>

                      <ol>
                        <li>Open the Climate Model. To test only El Ni&ntilde;o/La Ni&ntilde;a cycles, use the slide bar to set the other three forcings equal to zero.</li>
                        <li>
                          Adjust the size of the El Ni&ntilde;o/La Ni&ntilde;a cycles with the slide bar. When you do this, you are increasing or decreasing the effect of the forcing relative to the observed temperature record. Answer the following questions:
                          <ol type="a">
                            <li>In what years was El Ni&ntilde;o the largest?</li>
                            <li>In what years was La Ni&ntilde;a the largest?</li>
                            <li>Do El Ni&ntilde;o events and temperature correlate? What is your evidence?</li>
                          </ol>
                        </li>
                        <li>Look at the red, vertical bar next to the graph. It shows how much of the temperature (Ta) can be explained by the El Ni&ntilde;o/La Ni&ntilde;a cycles. What is the highest value you can get?</li>
                        <li>Write a claim about the relationship between El Ni&ntilde;o/La Ni&ntilde;a cycles and global temperatures.</li>
                      </ol>

                      <div className="col-12 text-center">
                        <iframe
                          className="embed-container w-100"
                          src="https://ecm.coloradocollege.edu/interactive"
                          title="Climate model interactive"
                        />
                      </div>
                      <p>If you are unable to see the interactive, click <a href="https://ecm.coloradocollege.edu/interactive" target="_blank" rel="noopener noreferrer">here&nbsp;<i className="fas fa-external-link-alt"></i></a>to open it in a new tab.</p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="volcanic-aerosols" className="cardHeader">
                      <h2>
                        <span className="headerFontBlue">Explore More: El Ni&ntilde;o/La Ni&ntilde;a Cycles</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p className="lead">El Ni&ntilde;o/La Ni&ntilde;a Cycles</p>
                      <p>
                        Several pieces of evidence show that El Ni&ntilde;o/La Ni&ntilde;a cycles influence the temperature of the atmosphere. First, air above the oceans in the tropics is heated by the warm ocean water. When surface waters are warmer, it leads to extra heating of the air above. Scientists can measure that extra heating of the surface waters and air. (To see the distribution of hot water, click on the El Ni&ntilde;o Animation.) Second, El Ni&ntilde;o/La Ni&ntilde;a cycles affect climate and the carbon cycle globally. For example, rainfall patterns can be strongly altered in parts of the United States. Third, climate data show that Earth's temperature responds about 5-8 months after the peaks or valleys in the El Ni&ntilde;o/La Ni&ntilde;a cycles. This is the lag time for the affect to spread from the equator to the entire atmosphere.
                      </p>

                      <div id="" className="embed-container">
                        <iframe title="Visualizing-El-Nino" className="videoSetting" src="https://player.vimeo.com/video/307895322" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="volcanic-aerosols" className="cardHeader">
                      <h2>
                        C. <span className="headerFontBlue">Forcing: Volcanic Aerosols</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        <strong>Aerosols</strong> are very small particulates in air. They stay suspended in the air, and include droplets of liquid or fine pieces of dust. Erupting volcanos eject this mixture into the air.
                      </p>

                      <div id="Volcano" className="embed-container mb-3">
                        <iframe title="Volcano" className="videoSetting" src="https://player.vimeo.com/video/307895725" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                      </div>

                      <p>
                        But not all volcanic eruptions are the same. Some eruptive clouds do not go above the troposphere. The <strong>troposphere</strong> is the lower part of the atmosphere, from Earth's surface to 12-15 km in altitude. To have the largest effect on Earth's temperature, the eruptive cloud needs to go higher.
                      </p>

                      <p>
                        Volcanic aerosols have to be ejected into the <strong>stratosphere</strong>. The stratosphere is the layer of the atmosphere above the troposphere. In the stratosphere, volcanic aerosols reflect some of the Sun's energy back to space. The reflected energy never enters the Earth system, and thus cannot contribute to the temperature of the atmosphere. The amount of reflected energy is the greatest when the aerosols in the stratosphere are along the equator, which is where most of the solar radiation enters Earth.
                      </p>

                      <p>
                        For example, Mount Pinatubo, a volcano in the Philippines, began to awaken in May 1991. The Philippines is a country in Asia along the equator. A massive eruption in June 1991 sent a cloud of volcanic aerosols into the stratosphere. Images from a NASA satellite show the stratosphere before the eruption (April 1991), and then after. Initially, the volcanic aerosols were mostly along the equator (June). Then they spread into the northern and southern hemispheres (August-September).
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3 imageBorder"
                            fluid={props.data.image12.childImageSharp.fluid}
                            alt="SAGE 11 1020 nm optical depth"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="largeImageCenter img-fluid imageBorder mb-3 d-none d-md-block"
                        fluid={props.data.image12.childImageSharp.fluid}
                        alt="SAGE 11 1020 nm optical depth"
                      />

                      <p className="lead">Philippines Particle Animation</p>
                      <div id="Volcano" className="embed-container mb-3">
                        <iframe title="particles" className="videoSetting" src="https://player.vimeo.com/video/307896250" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                      </div>

                      <p>
                        Watch the animation of mixing of volcanic aerosols in the atmosphere after the Mount Pinatubo eruption. For more about volcanic aerosols and climate, click on Volcanic Eruptions.
                      </p>

                      <p className="lead">Climate Model &amp; Volcanic Aerosols</p>
                      <p>
                        Use the climate model to test how volcanic aerosols in the stratosphere correlate with the global temperatures.
                      </p>

                      <ol start="5">
                        <li>Open the climate model. Adjust the slide bars so that you are able to test <em>only</em> volcanic aerosols.</li>
                        <li>What years had volcanic aerosol events? Do they lead to warming or cooling?</li>
                        <li>The early 1980s saw two large volcanic eruptions: Mount St. Helens in Washington State (May 1980) and El Chich&oacute;n in southern Mexico (March-April 1982). It takes volcanic aerosols 4-8 months to spread in the stratosphere. From the climate model, which eruption had a larger impact on global temperature? What was the effect?</li>
                        <li>Look at the vertical bar next to the graph. Move the slide bar to adjust the size of the volcanic aerosols. What is the highest value you can get for the influence of volcanic aerosols on Earth's temperature?</li>
                        <li>
                          A student wrote the claim below about volcanic eruptions and global temperatures. What do you think of the claim? Use the climate model to explain your answer. <br />
                          <em>"Volcanic explosions can have a large impact on climate. They lead to increases in global temperature because of the heat released to the atmosphere during the volcanic eruption."</em>
                        </li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="volcanic-aerosols" className="cardHeader">
                      <h2>
                        <span className="headerFontBlue">Explore More: Volcanic Eruptions</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p className="lead">Volcanic Eruptions&mdash;Severe Stratospheric Sulfates</p>
                      <p>
                        Human history shows that volcanic eruptions affect Earth's climate. For example, the 1815 eruption of Tambora in Indonesia saw global impacts. New England had snow in the summer, while Europe saw crop failure and famine. For several years, particles in the air caused blazing-red sunsets and sunrises. Similar events occurred again when Krakatoa (Indonesia) erupted in 1883. Scientists estimate that global temperatures in the mid-1880s decreased about 1.0-1.2&deg;C after this massive event.
                      </p>

                      <p>
                        So, do volcanoes merely correlate, or are they a cause of the temperature decrease? Both! They correlate with temperature and are cause of temperature change. There are several reasons for this.
                      </p>

                      <p>
                        First, the temperature decrease comes after the large eruption by 5-10 months. You cannot predict when the eruptions will occur; so, when there is an eruption, scientists can test whether cooling follows. Second, scientists have tested the reason for the forcing. Sulfur gases from volcanos are largely of sulfur dioxide (SO<sub>2</sub>). These molecules react with water to form sulfate (SO<sub>4</sub>)<sup>-2</sup> particles in the stratosphere. Volcanic ash in the stratosphere quickly settles out, but the sulfate can remain in the atmosphere for more than a year. The sulfate (SO<sub>4</sub>)<sup>-2</sup> particles reflect and scatter solar energy back to space. That energy never enters the troposphere.
                      </p>

                      <p>
                        The graph below shows the record of volcanic particles in the stratosphere over the past 160 years. These data have been important in testing the relationship between volcanic aerosols and Earth's temperature.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={ stratAero13 }
                            alt="Stratospheric Aerosols, Volcanic Eruptions"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter img-fluid d-none d-md-block"
                        src={ stratAero13 }
                        alt="Stratospheric Aerosols, Volcanic Eruptions"
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="solar-cycles" className="cardHeader">
                      <h2>
                        D. <span className="headerFontBlue">Forcing: Solar Cycles</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image14.childImageSharp.fluid}
                            alt="Sun"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageRight img-fluid d-none d-md-block ml-3 mb-3"
                        fluid={props.data.image14.childImageSharp.fluid}
                        alt="Sun"
                      />

                      <p>
                        Almost all of the energy to Earth's surface comes from the Sun. Greenhouse gases help to trap some of that thermal energy in the atmosphere. In fact, without greenhouse gases, Earth would probably be too cold for life. But does the amount of energy from the sun ever change or vary? Imagine if you had a dimmer switch that could brighten, and then dim, the Sun. You can then imagine how changing the energy from the Sun could affect Earth's temperature.
                      </p>

                      <p>
                        Scientists have investigated sunspots to see if they affect the amount of energy coming to Earth. <strong>Sunspots</strong> are dark, cooler areas on the Sun's surface. They can also produce solar flares and massive eruptions that can cause power outages and failures in satellites and telecommunications. Because of this, sunspots are studied closely by scientists and even the U.S. military. Click on the videos to zoom in on a sunspot, or see a dazzling, active region around a sunspot.
                      </p> <br /> <br />

                      <div id="" className="embed-container mb-3">
                        <iframe title="Sunspot-Closeup" className="videoSetting" src="https://player.vimeo.com/video/307896911" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                      </div>

                      <div id="" className="embed-container mb-3">
                        <iframe title="Dazzling_Solar_Eruption" className="videoSetting" src="https://player.vimeo.com/video/307897324" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                      </div>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image15.childImageSharp.fluid}
                            alt="Sunspot cycle record, 1950-2010"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="largeImageCenter img-fluid d-none d-md-block mb-3"
                        fluid={props.data.image15.childImageSharp.fluid}
                        alt="Sunspot cycle record, 1950-2010"
                      />

                      <p>
                        Scientists have seen that the number of sunspots increase and decrease in a clear pattern. Sunspots have an 11-year cycle. Researchers from NASA, NOAA, and European groups have found a connection between the number of sunspots and the amount of solar radiation to Earth. When the sunspots are at a peak, solar radiation is at a peak too. Solar radiation is lowest at the valleys in the sunspot cycle. You can think of the sunspot curve like a dimmer switch that controls the energy output of a light bulb. Watch "Comparison of Solar Activity" to compare the brightness and radiation between a peak (1999) and a valley in the cycle (1996).
                      </p>

                      <div id="" className="embed-container mb-3">
                        <iframe title="Comparison_Solar_Activity" className="videoSetting" src="https://player.vimeo.com/video/307897629" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                      </div>

                      <p className="lead">Climate Model &amp; Solar Cycles</p>
                      <p>
                        Use these steps with the climate model to test how solar cycles relate to changes in Earth's climate.
                      </p>

                      <ol start="10">
                        <li>Return to the model. Adjust the variables so that everything is zero, except solar cycles.</li>
                        <li>From 1979 through 2010, you see three peaks. Check that these match cycles in the sunspot graph above.</li>
                        <li>
                          Now, adjust the size of solar cycles, El Ni&ntilde;o/La Ni&ntilde;a cycles, and volcanic aerosols to "1." These three are Earth's natural forcings.
                          <ol type="a">
                            <li>Which of these show the most cooling? How much more?</li>
                            <li>Which show the most warming? How much more?</li>
                            <li>Look at the vertical bar next to the graph. How close to the best-fit model do you get with just the natural forcings?</li>
                            <li>What happens if you double the size of the natural forcings? Do this by moving the slide-bars from 1 to 2.</li>
                          </ol>
                        </li>
                        <li>
                          The temperature response from solar cycles has an up-and-down pattern. That explains some <em>variability</em> in temperature. In contrast, do the solar cycles lead to any overall <em>change</em> in temperature from 1979 through 2010? Test this question with the climate model, and then complete the blanks below. <br />
                          <em>Solar cycles lead to &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F; <strong>variability</strong> in Earth's mean temperature.</em> <br />
                          <em>Solar cycles lead to &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F; overall <strong>change</strong> in Earth's mean temperature.</em>
                        </li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="volcanic-aerosols" className="cardHeader">
                      <h2>
                        <span className="headerFontBlue">Explore More: Why More Sunspots Mean More Radiation</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p className="lead">Why More Sunspots Mean More Radiation</p>
                      <p>
                        Scientists have shown that the number of sunspots correlate with increased solar radiation to Earth. But if sunspots are cooler regions, you might expect the
                        opposite&mdash;more sunspots mean less total radiation. There is a reason why this is not the case.
                      </p>

                      <p>
                        Look at the images of the Sun from late March 2001. The first one has several trails of sunspots. The second is also late March 2001, but with a different telescope lens. It shows large, bright patches joining around the sunspots. These are called <strong>faculae</strong>. When the number of sunspots increase, the size and number of faculae increase even faster. The two effects of <em>facular brightening</em> and <em>sunspot darkening</em> are shown in the graph below. The net effect is greater solar radiation at peaks in the sunspot cycle. The brightening effect of the faculae is greater than the dimming effect of the sunspots.
                      </p>

                      <Row>
                        <Col>
                          <div className="image-wrapper">
                            <img
                              className="image image-bordered"
                              src={ sunspotsFaculaeA16 }
                              alt="El Nino, January 1998. La Nina, June 1998"
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="image-wrapper">
                            <img
                              className="image image-bordered"
                              src={ sunspotsFaculaeB16 }
                              alt="El Nino, January 1998. La Nina, June 1998"
                            />
                          </div>
                        </Col>
                        <div className="image-caption image-caption-bordered mb-3">
                          <strong>Sunspots and faculae.</strong> (a) Image of sunspots on March 29, 2001. (b) An image on March 28, 2001, shows more large, bright areas (faculae) than sunspots. The "brightening" from faculae is greater than the "darkening" from sunspots.
                        </div>
                      </Row>

                      <p>
                        You can find many other incredible videos of sunspots, faculae, and solar eruptions at the website for the <a href="https://sohowww.nascom.nasa.gov/" target="_blank" rel="noopener noreferrer">Solar and Heliospheric Observatory (SOHO)&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a>
                      </p>

                      <ul>
                        <li>
                          <a href="http://sohowww.nascom.nasa.gov/gallery/Movies/sunspots.html" target="_blank" rel="noopener noreferrer">http://sohowww.nascom.nasa.gov/gallery/Movies/sunspots.html&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a>
                        </li>
                        <li>
                          <a href="http://sohowww.nascom.nasa.gov/gallery/Movies/flares.html" target="_blank" rel="noopener noreferrer">http://sohowww.nascom.nasa.gov/gallery/Movies/flares.html&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a>
                        </li>
                        <li>
                          <a href="http://sohowww.nascom.nasa.gov/gallery/Movies/filaments.html" target="_blank" rel="noopener noreferrer">http://sohowww.nascom.nasa.gov/gallery/Movies/filaments.html&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a>
                        </li>
                      </ul>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={ sunspotBrightening17 }
                            alt="Sunspot cycle brightening and dimming (W/m^2), 1978-2011"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter img-fluid d-none d-md-block mb-4"
                        src={ sunspotBrightening17 }
                        alt="Sunspot cycle brightening and dimming (W/m^2), 1978-2011"
                      />

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={ netSolar18 }
                            alt="Net solar irradiance (W/m^2), 1978-2011"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter img-fluid d-none d-md-block mb-4"
                        src={ netSolar18 }
                        alt="Net solar irradiance (W/m^2), 1978-2011"
                      />

                      <Row>
                        <Col>
                          <div className="image-wrapper mr-0">
                            <img
                              className="image image-bordered"
                              src={ satelliteBuild19 }
                              alt="Satellite being built"
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="image-wrapper ml-0">
                            <img
                              className="image image-bordered"
                              src={ satelliteSpace20 }
                              alt="Satellite in space"
                            />
                          </div>
                        </Col>
                        <div className="image-caption image-caption-bordered">
                          SORCE. The SORCE satellite closely watches the Sun. It measures the total solar radiation coming to Earth from the Sun.
                        </div>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="human-factors" className="cardHeader">
                      <h2>
                        E. <span className="headerFontBlue">Forcing: Human Factors</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image21.childImageSharp.fluid}
                            alt="Farming"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageRight img-fluid d-none d-md-block ml-3"
                        fluid={props.data.image21.childImageSharp.fluid}
                        alt="Farming"
                      />

                      <p>
                        You have explored three natural forcings on Earth's temperature. But what about humans? In Unit 2, you saw that using fossil fuels increases CO<sub>2</sub> in the atmosphere; in Unit 1, you learned that CO<sub>2</sub> is a greenhouse gas. More greenhouse gases lead to more warming of the atmosphere. At the same time, fossil fuels are vital to you and your community for transportation and electricity. Humans can't suddenly stop using fossil fuels. The climate model lets you investigate the period from 1979-2011. With those data, you can test the influence humans have had. This will help you make more informed decisions for the future.
                      </p>

                      <p>
                        Human factors on climate are also called <strong>anthropogenic</strong> forcings. Forcings from the Sun have one influence on temperature&mdash;the radiant energy to Earth. In contrast, human influences on climate have several parts. The main factor is CO<sub>2</sub> added to the atmosphere from using fossil fuels. As you saw in Unit 2, this differs from CO<sub>2</sub> that is a part of the breathing biosphere.
                      </p>

                      <p>
                        The second largest source of CO<sub>2</sub> to the atmosphere is from humans is making cement, a vital part of concrete. Concrete is a probably a part of the building where you are sitting right now, as well as the roads, sidewalks, and street curbs in your community.
                      </p>

                      <p>
                        The third largest source of CO<sub>2</sub> to the atmosphere is how humans use land. This includes harvesting wood, as well as using land for agriculture and producing food.
                      </p>

                      <p>
                        But some human activities also remove CO<sub>2</sub> from the atmosphere. These are included in the model you will use below. For example, when forests grow or farm fields are not used, CO<sub>2</sub> moves from air to the plants and soils. Similarly, new technologies are exploring how to "capture" CO<sub>2</sub> when your electrical energy is generated&mdash;so the CO<sub>2</sub> doesn't go to the atmosphere. Other new technologies can generate electrical energy by not using fossil fuels. You may have heard of these. These energy sources include nuclear, hydroelectric, solar, wind, wave, and geothermal energy.
                      </p>

                      <p className="lead">Climate Model &amp; Human Factors</p>
                      <p>
                        Your focus question for this lesson was, "What factors influence Earth's temperature?" Follow the steps below to investigate whether humans have a role.
                      </p>

                      <ol start="14">
                        <li>Return to the climate model. Adjust the forcings to test only the human influences.</li>
                        <li>
                          Look at the data from 1979 through 2010.
                          <ol type="a">
                            <li>What pattern do you see?</li>
                            <li>Do the data have more variability or less variability than the natural forcings? (In other words, is the data more spiky or less spiky than the other forcings?)</li>
                            <li>Look at the vertical bar next to the graph. How close to the best-fit do you get with only the human forcings?</li>
                          </ol>
                        </li>
                        <li>
                          You used an interactive in Lesson 2.3 for CO<sub>2</sub> at Mauna Loa. How does the line for the human influence in that model compare with the human influence in the climate model? <br />
                          <em>(Remember that you can turn off photosynthesis and respiration in the <Link to="/unit-2/2.2-exploring-limits/#aquariums-ups-and-downs">Mauna Loa</Link> model by clicking on "R&amp;P=0".)</em>
                        </li>
                        <li>
                          Complete these sentences about how human factors influence the temperature of Earth. <br />
                          <em>Human factors lead to &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F; <strong>variability</strong> in Earth's mean temperature.</em> <br />
                          <em>Human factors lead to &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F; overall <strong>change</strong> in Earth's mean temperature.</em>
                        </li>
                      </ol>

                      <p className="lead">SHOW ME THE DATA</p>
                      <p>
                        You can download the data used in the Climate Model. The best detail is from 1979 through 2010. It includes El Ni&ntilde;o/La Ni&ntilde;a cycles, volcanic events, solar cycles, and human factors. By using X-Y graphs, you can compare different data sets. For example, you can test how the data for El Ni&ntilde;o/La Ni&ntilde;a cycles or volcanic aerosols compare with the temperature data. Or, you could graph El Ni&ntilde;o/La Ni&ntilde;a cycles against solar cycles to see if these are correlated. You just might find something interesting!
                      </p>

                      <a
                        href="http://media.bscs.org/media/carbonconnections/images/student_edition/02_curriculum/unit3/documents/ta_evsa_data_summary_1979_to_2011.xls"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button className="mb-3">
                          Download Climate Model Data&nbsp;&nbsp;<i className="fas fa-cloud-download-alt"></i>
                        </Button>
                      </a>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="reflect-and-connect" className="cardHeader">
                      <h2>
                        F. <span className="headerFontBlue">Reflect and Connect</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <ol start="18">
                        <li>
                          You used a sophisticated climate model today. It is used by NASA scientists and other scientists. Answer these questions about the climate model.
                          <ol type="a">
                            <li>How would you describe to a family member what you investigated in class today?</li>
                            <li>Imagine that you and your family were looking at the <em>Carbon Connections</em> website. What would you show them?</li>
                          </ol>
                        </li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <ButtonNext
                ButtonText="3.3 Future Forcings"
                NextLink="/unit-3/3.3-future-forcings/"
              />

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default TestingForcings32

export const query = graphql`
  query {
    image9: file(relativePath: { eq: "student-pages/unit-3/09_volcanic_eruption.jpg" }) {
      ...rowImage
    }
    image10: file(relativePath: { eq: "student-pages/unit-3/10_el_nino_nina.jpg" }) {
      ...rowImage
    }
    image12: file(relativePath: { eq: "student-pages/unit-3/12_sage_11.jpg" }) {
      ...rowImage
    }
    image14: file(relativePath: { eq: "student-pages/unit-3/14_sun.jpg" }) {
      ...rowImage
    }
    image15: file(relativePath: { eq: "student-pages/unit-3/15_sunspot_cycle.png" }) {
      ...rowImage
    }
    image21: file(relativePath: { eq: "student-pages/unit-3/21_farming.jpg" }) {
      ...rowImage
    }
  }
`
